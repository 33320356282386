* {
  margin: 0;
  padding: 0;
}

html {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  background-color: #333;
  /* font-size 1em = 10px on default browser settings */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
